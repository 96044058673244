/* =============================
        FONTS
============================= */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* =============================
        HTML & BODY
============================= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 1rem;
  overflow-x: hidden;
}

body {
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: nth($color, 2);
}

.DBlock,
header,
section,
footer {
  float: left;
  width: 100%;
  height: 100%;
  display: block;
}

.DFlex {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: normal;
  text-transform: none;
  line-height: normal;
  margin-bottom: 0;
}

label {
  margin-bottom: 0;
}

ul,
ol {
  list-style: none;
  margin-bottom: 0;
}

.FocusNone,
a:focus,
textarea:focus,
input:focus,
select:focus {
  outline: none !important;
}

.TransAni {
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

select {
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
