@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";
@import "html";
@import "auth";
@import "dashboard";

.mapOuterDiv > div {
  width: 100%;
  height: 100%;
}

.mapContainer {
  height: 100% !important;
}

.ez__Header {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11);
}

.mapDirectionHeight {
  height: 500px;
}

.imageModalHeight {
  height: 500px;
  @include Media(575) {
    height: 300px;
  }
}

//Loader
@keyframes ScaleInOut {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.5);
  }
}

.transparency {
  background: rgba(0, 0, 0, 0.3);
}

.dot1 {
  width: 15px;
  height: 15px;
  animation: ScaleInOut 0.6s ease-in-out 0.2s infinite alternate;
}

.dot2 {
  width: 15px;
  height: 15px;
  animation: ScaleInOut 0.6s ease-in-out 0.4s infinite alternate;
}

.dot3 {
  width: 15px;
  height: 15px;
  animation: ScaleInOut 0.6s ease-in-out 0.6s infinite alternate;
}

//Checkboxes Style
input[type="checkbox"] {
  accent-color: #228b22;
}

//Select style
.headerFormSelect {
  .select__value-container {
    padding: 5px !important;
  }
}
.disableSelect {
  .select__control {
    border-color: #808080 !important;
  }
  .css-tj5bde-Svg {
    color: #808080 !important;
    fill: #808080 !important;
  }
}
.select__value-container {
  padding: 8px !important;
}
.css-v68sna-control,
.select__control:hover,
.select__control:focus,
.css-13cymwt-control {
  border-color: #1f1f1f !important;
  box-shadow: none !important;
}
.css-1u9des2-indicatorSeparator {
  background-color: #1f1f1f !important;
}
.css-tj5bde-Svg {
  fill: #1f1f1f !important;
  color: #1f1f1f !important;
}

//Google Input
.groupBlur {
  &:hover {
    .image-blur {
      filter: blur(3px);
      transition: filter 0.3s ease;
    }
  }
}

.status-in-progress {
  background-color: #ffb8000d;
  color: #ffb800;
}
.status-completed {
  background-color: #ebffeb;
  color: #228b22;
}
.status-upcoming {
  background-color: #f49a7a1a;
  color: #f49a7a;
}
.status-not-schedule {
  background-color: #ebebeb;
  color: #292d32;
}

//Input Type Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

table th,
table td {
  border: 1px solid #eee;
  padding: 10px;
}

// Scroll bar style
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #228b22;
  @include BorRadius(8px);
}

.react-datepicker__day.react-datepicker__day--selected {
  background-color: #228B22;
}